import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export default function useGetBillingAccounts() {
  const REACT_APP_API_V2_BASE_URL = process.env.REACT_APP_API_V2_BASE_URL;
  return useQuery({
    queryKey: ['get-billing-accounts'],
    queryFn: async () => {
        const response = await axios.get(
          `${REACT_APP_API_V2_BASE_URL}/billing-account`,
        );
        const data = await response.data;
        return data;
    },
    refetchOnWindowFocus: false,
  })
}