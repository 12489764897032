import { Box, CircularProgress, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useState } from 'react';
import { LimitedBackdrop } from '../common/components/LimitedBackdrop/LimitedBackdrop';
import useGetBillingAccounts from './useGetBillingAccounts.hook';
import { CheckCircle as ValidCardIcon, Cancel as InvalidCardIcon, Edit } from '@material-ui/icons/';
import EditBillingAccountModal from './EditBillingAccountModal.component';

function BillingAccountCard() {
  const { isError, isLoading, isSuccess, data } = useGetBillingAccounts();
  const [open, setOpen] = useState(false);
  const [currentBillingAccount, setCurrentBillingAccount] = useState(null);

  const handleEdit = billingAccount => {
    setCurrentBillingAccount(billingAccount);
    setOpen(true);
  };
  return (
    <Box>
      {isError && (
        <Box>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            <Typography>Something went wrong!</Typography>
          </Alert>
        </Box>
      )}
      {isLoading && (
        <Box
          style={{
            position: 'relative',
            height: '110px',
          }}
        >
          <LimitedBackdrop open={isLoading} style={{ padding: '8px' }}>
            <CircularProgress color="primary" />
          </LimitedBackdrop>
        </Box>
      )}
      {!isLoading &&
        (isSuccess && data?.length > 0 ? (
          data.map(billingAccount => {
            const {
              _id,
              billingType,
              name,
              prefix,
              savePdf,
              szamlaAgentApiKey,
              vatType,
            } = billingAccount;
            return (
              <Box
                key={_id}
                style={{
                  display: 'flex',
                  gap: '15px',
                  border: '1px solid #ccc',
                  backgroundColor: 'white',
                  margin: '8px',
                  padding: '8px',
                  borderRadius: '4px',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <Typography>
                    <strong>Name:</strong> {name}
                  </Typography>
                  <Typography>
                    <strong>Számla Agent API Key:</strong> {szamlaAgentApiKey}
                  </Typography>
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>
                      <strong>Save PDF:</strong>
                    </Typography>
                    <Typography style={{ display: 'flex', alignSelf: 'center', marginLeft: '4px' }}>
                      {savePdf ? (
                        <ValidCardIcon style={{ color: 'green' }} />
                      ) : (
                        <InvalidCardIcon color="error" />
                      )}
                    </Typography>
                  </Box>
                  <Typography>
                    <strong>Prefix:</strong> {prefix}
                  </Typography>
                  <Typography>
                    <strong>Billing Type:</strong> {billingType}
                  </Typography>
                  <Typography>
                    <strong>VAT Type:</strong> {vatType}
                  </Typography>
                </Box>
                <Box
                  onClick={() => {
                    handleEdit(billingAccount);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <Edit />
                </Box>
              </Box>
            );
          })
        ) : (
          <Box>
            <Alert severity="warning">
              <AlertTitle>Not found</AlertTitle>
              <Typography>No ELB card was found with provided keyword.</Typography>
            </Alert>
          </Box>
        ))}
      {currentBillingAccount && (
        <EditBillingAccountModal
          open={open}
          setOpen={setOpen}
          billingAccount={currentBillingAccount}
        />
      )}
    </Box>
  );
}

export default BillingAccountCard;
