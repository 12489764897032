import { useQuery } from '@tanstack/react-query';
import axios from 'axios';


export const useGetActiveV2TicketsByEvent = (eventId) => {
    return useQuery({
        queryKey: ['active-tickets-v2', eventId],
        queryFn: async () => {
            const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL
            const response = await axios.get(
                `${REACT_APP_SERVER_URL}event/${eventId}/active-v2Tickets`,
            );
            const data = await response.data;
            return data;
        },
        refetchOnWindowFocus: false,
    });
};
