import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

export const useCreateTicketChecking = () => {
    const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: ['new-event-ticket-check'],
        mutationFn: async entryData => {
            console.log('entryData: ', entryData);
            const response = await axios.post(`${REACT_APP_SERVER_URL}checking/new-ticket-entry`, entryData);
            const data = await response.data;
            return data;
        },
        refetchOnWindowFocus: false,
        onSuccess: () =>
            queryClient.invalidateQueries(['active-tickets-v2']),
    });
};
