import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import useUpdateBillingAccount from './useUpdateBillingAccount';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { BillingTypeEnum, VatTypeEnum } from './BillingAccountEnums';

function EditBillingAccountModal({ billingAccount, open, setOpen }) {
  const { _id } = billingAccount || {};
  const { enqueueSnackbar } = useSnackbar();
  const { mutate, isSuccess, isError } = useUpdateBillingAccount(_id);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
      enqueueSnackbar('Succesfully edited billing account!', 'success', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar("Couldn't edit billing account!", 'error', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
  }, [isError]);

  const formik = useFormik({
    initialValues: {
      billingType: billingAccount?.billingType,
      name: billingAccount?.name,
      prefix: billingAccount?.prefix,
      savePdf: billingAccount?.savePdf,
      szamlaAgentApiKey: billingAccount?.szamlaAgentApiKey,
      vatType: billingAccount?.vatType,
    },
    validationSchema: yup.object({
      name: yup.string().required(),
      szamlaAgentApiKey: yup.string().required(),
      prefix: yup.string().optional(),
      savePdf: yup.bool().required(),
      billingType: yup
        .mixed()
        .oneOf(Object.values(BillingTypeEnum))
        .required(),
      vatType: yup
        .string()
        .oneOf(Object.values(VatTypeEnum))
        .required(),
    }),
    onSubmit: async values => {
      console.log('values before mutation: ', values);
      mutate(values);
    },
  });

  useEffect(() => {
    if (open) {
      formik.initialValues.name = billingAccount.name;
      formik.initialValues.szamlaAgentApiKey = billingAccount.szamlaAgentApiKey;
      formik.initialValues.savePdf = billingAccount.savePdf;
      formik.initialValues.prefix = billingAccount.prefix;
      formik.initialValues.billingType = billingAccount.billingType;
      formik.initialValues.vatType = billingAccount.vatType;
    }
  }, [billingAccount, open]);

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Edit Billing Account</DialogTitle>
      <DialogContent>
        <form>
          <Grid style={{ display: 'flex', flexDirection: 'column' }}>
            <Grid item>
              <TextField
                required
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ margin: '8px 0px', alignSelf: 'center' }}
                id="name"
                name="name"
                fullWidth
                label="Billing Account name"
                type="text"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item>
              <TextField
                required
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ margin: '8px 0px', alignSelf: 'center' }}
                id="szamlaAgentApiKey"
                name="szamlaAgentApiKey"
                fullWidth
                label="Számla Agent API Key"
                type="text"
                value={formik.values.szamlaAgentApiKey}
                onChange={formik.handleChange}
                error={formik.touched.szamlaAgentApiKey && Boolean(formik.errors.szamlaAgentApiKey)}
                helperText={formik.touched.szamlaAgentApiKey && formik.errors.szamlaAgentApiKey}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                style={{ alignSelf: 'center', textAlign: 'left' }}
                control={
                  <Checkbox
                    checked={formik.values.savePdf}
                    value={formik.values.savePdf}
                    onChange={formik.handleChange}
                    name="savePdf"
                    id="savePdf"
                    color="primary"
                  />
                }
                label="Save PDF?"
              />
            </Grid>
            <Grid item>
              <TextField
                required
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ margin: '8px 0px', alignSelf: 'center' }}
                id="prefix"
                name="prefix"
                fullWidth
                label="Prefix"
                type="text"
                value={formik.values.prefix}
                onChange={formik.handleChange}
                error={formik.touched.prefix && Boolean(formik.errors.prefix)}
                helperText={formik.touched.prefix && formik.errors.prefix}
              />
            </Grid>
            <Grid item>
              <FormControl
                fullWidth
                variant="outlined"
                style={{
                  alignSelf: 'center',
                  margin: '8px 0px',
                }}
              >
                <InputLabel>Billing Type*</InputLabel>
                <Select
                  id="billingType"
                  name="billingType"
                  value={formik.values.billingType}
                  onChange={formik.handleChange}
                  error={formik.touched.billingType && Boolean(formik.errors.billingType)}
                >
                  <MenuItem value="" disabled></MenuItem>
                  {Object.values(BillingTypeEnum).map((billingType, index) => {
                    return (
                      <MenuItem key={index} value={billingType}>
                        {billingType}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormHelperText style={{ color: 'red' }}>
                {formik.touched.type && formik.errors.type}
              </FormHelperText>
            </Grid>
            <Grid item>
              <FormControl
                fullWidth
                variant="outlined"
                style={{
                  alignSelf: 'center',
                  margin: '8px 0px',
                }}
              >
                <InputLabel>VAT Type*</InputLabel>
                <Select
                  id="vatType"
                  name="vatType"
                  value={formik.values.vatType}
                  onChange={formik.handleChange}
                  error={formik.touched.vatType && Boolean(formik.errors.vatType)}
                >
                  <MenuItem value="" disabled></MenuItem>
                  {Object.values(VatTypeEnum).map((vatType, index) => {
                    return (
                      <MenuItem key={index} value={vatType}>
                        {vatType}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormHelperText style={{ color: 'red' }}>
                {formik.touched.type && formik.errors.type}
              </FormHelperText>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={formik.handleSubmit}
          disabled={formik.isValid && !formik.dirty}
        >
          Edit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditBillingAccountModal;
