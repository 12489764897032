import { Box, Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import { format } from 'date-fns';
import DownloadBilling from './DownloadBilling.component';

function BillingCard({ billing }) {
  const valueStyle = { fontWeight: 'bold' };
  return (
    <Card>
      <CardContent>
        <Box style={{ display: 'flex', gap: '20px' }}>
          <Typography>Billing ID:</Typography>
          <Typography style={valueStyle}>{billing?.billingId || 'No ID'}</Typography>
        </Box>
        <Box style={{ display: 'flex', gap: '20px' }}>
          <Typography>Billing Type:</Typography>
          <Typography style={valueStyle}>{billing?.type}</Typography>
        </Box>
        <Box style={{ display: 'flex', gap: '20px' }}>
          <Typography>Created At:</Typography>
          <Typography style={valueStyle}>{format(new Date(billing.createdAt), 'yyyy-MM-dd hh:mm')}</Typography>
        </Box>
        <Box style={{ display: 'flex', gap: '20px' }}>
          <Typography>Billing Account ID:</Typography>
          <Typography style={valueStyle}>{billing?.billingAccountId}</Typography>
        </Box>
        <Box style={{ display: 'flex', gap: '20px' }}>
          <Typography>Order ID:</Typography>
          <Typography style={valueStyle}>{'TBD'}</Typography>
        </Box>
        <Box style={{ display: 'flex', gap: '20px' }}>
          <Typography>Summary:</Typography>
          <Typography style={valueStyle}>{billing?.sum}</Typography>
        </Box>
        <Box style={{ display: 'flex', gap: '20px' }}>
          <Typography>VAT Type:</Typography>
          <Typography style={valueStyle}>{'ÁFA (TBD)'}</Typography>
        </Box>
        {billing.successfullyIssued && (
          <Box style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <Typography>Download:</Typography>
            <Typography>
              {<DownloadBilling billingId={billing?.id} billingType={billing.type} />}
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
}

export default BillingCard;
