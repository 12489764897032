import { useQuery } from '@tanstack/react-query';
import axios from 'axios';


export const useGetBillingsByType = (payload) => {
    return useQuery({
        queryKey: ['billings', payload],
        queryFn: async () => {
            const REACT_APP_API_V2_BASE_URL = process.env.REACT_APP_API_V2_BASE_URL
            const { keyword, billingType } = payload;

            const defaultRoute = `billing?keyword=${keyword}`
            const route = billingType === '' ? defaultRoute : `${billingType}/${defaultRoute}`
            const response = await axios.get(
                `${REACT_APP_API_V2_BASE_URL}/${route}`
            );
            const data = await response.data;
            return data;
        },
        refetchOnWindowFocus: false
    });
};
