import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

export default function useUpdateBillingAccount(id) {
    const REACT_APP_API_V2_BASE_URL = process.env.REACT_APP_API_V2_BASE_URL;
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: ['update-billing-account', id],
        mutationFn: async (payload) => {
            const response = await axios.patch(
                `${REACT_APP_API_V2_BASE_URL}/billing-account/${id}`, payload
            );
            const data = await response.data;
            return data;
        },
        onSuccess: () =>
            queryClient.invalidateQueries(['get-billing-accounts']),
    })
}