export const BillingTypeEnum = {
    Invoice: 'Invoice',
    Receipt: 'Receipt',
    Certificate: 'Certificate',
}

export const VatTypeEnum = {
    TEHK: 'TEHK',
    TAHK: 'TAHK',
    TAM: 'TAM',
    AAM: 'AAM',
    EUT: 'EUT',
    EUKT: 'EUKT',
    FAFA: 'F.AFA',
    KAFA: 'K.AFA',
    H0: 'H0',
    EUE: 'EUE',
    EUFADE: 'EUFADE',
    EUFAD37: 'EUFAD37',
    ATK: 'ATK',
    NAM: 'NAM',
    EAM: 'EAM',
    KBAUK: 'KBAUK',
    KBAET: 'KBAET',
    NUM_0: '0',
    NUM_1: '1',
    NUM_2: '2',
    NUM_3: '3',
    NUM_4: '4',
    NUM_5: '5',
    NUM_6: '6',
    NUM_7: '7',
    NUM_8: '8',
    NUM_9: '9',
    NUM_10: '10',
    NUM_11: '11',
    NUM_12: '12',
    NUM_13: '13',
    NUM_14: '14',
    NUM_15: '15',
    NUM_16: '16',
    NUM_17: '17',
    NUM_18: '18',
    NUM_19: '19',
    NUM_20: '20',
    NUM_21: '21',
    NUM_22: '22',
    NUM_23: '23',
    NUM_24: '24',
    NUM_25: '25',
    NUM_26: '26',
    NUM_27: '27',
}