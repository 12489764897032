import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export default function useGetCheckingByCustomer(payload) {
    const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
    return useQuery({
        queryKey: ['get-checking-by-customer', payload],
        queryFn: async () => {
            const { customerId, checkingMethod, eventId } = payload
            const response = await axios.get(
                `${REACT_APP_SERVER_URL}checking/qr-code-checking-stats/${customerId}?checkingMethod=${checkingMethod}&eventId=${eventId}`,
            );
            const data = await response.data;
            return data;
        },
        refetchOnWindowFocus: false,
    })
}