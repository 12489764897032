import React from 'react';

import { getOperationName } from '../../../../apollo/helpers';
import { GET_BILLING_ACCOUNTS } from '../../graphql';

import { Collection } from '../../../common/components';
import BillingAccountsToolbar from '../BillingAccountsToolbar';
import BillingAccountsTable from '../BillingAccountsTable';
import BillingAccountCard from '../../../billing-accounts/BillingAccountCard.component';

function BillingAccounts() {
  return <BillingAccountCard />;
  // return (
  //   <Collection
  //     toolbar={<BillingAccountsToolbar refetchQueries={[getOperationName(GET_BILLING_ACCOUNTS)]} />}
  //     table={<BillingAccountsTable />}
  //   />
  // );
}

export default BillingAccounts;
