import React from 'react';

import * as Types from '../../../../utils/propTypes';
import routes from '../../../../utils/routes';

import { GET_EVENTS } from '../../graphql';

import { Table, CollectionTable } from '../../../common/components';

const columns = [
  {
    name: 'extendedName',
    label: 'Name',
    options: {
      filterType: 'textField',
      renderCell: (value, { id, name }) => routes.event.createLink(id, name, value || name),
    },
  },
  {
    name: 'from',
    label: 'From',
    type: 'dateTime',
    options: {},
  },
  {
    name: 'until',
    label: 'Until',
    type: 'dateTime',
    options: {
      // filterList: [new Date(), null],
    },
  },
  {
    name: 'location',
    label: 'Location',
    options: {
      filterType: 'textField',
      display: false,
    },
  },
  {
    name: 'forSale',
    label: 'For Sale',
    options: {
      renderCell: forSale => (forSale ? 'Yes' : 'No'),
      filter: false,
    },
  },
  {
    name: 'ticketLimit',
    label: 'Ticket Limit',
    type: 'number',
  },
  {
    name: 'isExternal',
    label: 'External',
    options: {
      renderCell: val => (val ? 'Yes' : 'No'),
      filter: false,
    },
  },
];

function EventsTable({ events }) {
  return (
    <CollectionTable>
      <Table
        title="Events List"
        collectionName="events"
        query={events ? undefined : GET_EVENTS}
        data={events}
        columns={columns}
      />
    </CollectionTable>
  );
}

EventsTable.propTypes = {
  events: Types.events,
};

export default EventsTable;
