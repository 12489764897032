import { Box, Button, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import EventChecking from '../EventChecking/EventChecking';
import { TabPanel } from '../../../../common/components';
import EventCheckingTickets from '../EventCheckingTickets';
import CheckingSearchBar from '../../../../checking/CheckingSearchBar.component';
import { QrCodeReading } from '../../../../qr-code-reading/components/QrCodeReading';

const TABS = {
  TAB_SEARCH_BAR_CHECK: 'TAB_SEARCH_BAR_CHECK',
  TAB_EVENT_CHECKING_TEXT_INPUT: 'TAB_EVENT_CHECKING_TEXT_INPUT',
  TAB_EVENT_CHECKING_TICKET_LIST: 'TAB_EVENT_CHECKING_TICKET_LIST',
  TAB_QRCODE_READER: 'TAB_QRCODE_READER',
};

function EventCheckingPage() {
  const [tabValue, setTabValue] = useState(TABS.TAB_SEARCH_BAR_CHECK);
  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
  };
  const urlParams = new URLSearchParams(window.location.search);
  const eventId = urlParams.get('eventId');
  const eventName = urlParams.get('eventName');
  return (
    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box style={{ display: 'flex', alignSelf: 'flex-end', margin: '8px' }}>
        <Button
          variant="contained"
          color="primary"
          href={`/event-checking-stats?eventId=${eventId}&eventName=${encodeURIComponent(
            eventName,
          )}`}
        >
          Checking Stats
        </Button>
      </Box>
      <Box>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Search Bar Check" value={TABS.TAB_SEARCH_BAR_CHECK} />
          <Tab label="Text Input" value={TABS.TAB_EVENT_CHECKING_TEXT_INPUT} />
          <Tab label="Tickets List" value={TABS.TAB_EVENT_CHECKING_TICKET_LIST} />
          <Tab label="QR Code Reader" value={TABS.TAB_QRCODE_READER} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={TABS.TAB_SEARCH_BAR_CHECK}>
        <Typography variant="h3">Search bar check</Typography>
        <CheckingSearchBar isEventChecking={true} />
      </TabPanel>
      <TabPanel value={tabValue} index={TABS.TAB_EVENT_CHECKING_TEXT_INPUT}>
        <Typography variant="h3">Text Input</Typography>
        <EventChecking />
      </TabPanel>
      <TabPanel value={tabValue} index={TABS.TAB_EVENT_CHECKING_TICKET_LIST}>
        {/* <Typography variant="h3" style={{ textAlign: 'center', marginBottom: '8px' }}>
          Tickets List
        </Typography> */}
        <EventCheckingTickets />
      </TabPanel>
      <TabPanel value={tabValue} index={TABS.TAB_QRCODE_READER} style={{ width: '100%' }}>
        <Box style={{ display: 'flex', justifyContent: 'center', marginRight: '180px' }}>
          <Typography variant="h3">QR Code Reader</Typography>
        </Box>
        <QrCodeReading isEventChecking={true} />
      </TabPanel>
    </Box>
  );
}

export default EventCheckingPage;
