import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { LimitedBackdrop } from '../../../../common/components/LimitedBackdrop/LimitedBackdrop';
import { useGetActiveV2TicketsByEvent } from '../../../../tickets-v2/useGetActiveV2TicketsByEvent';
import { CheckCircle as CheckedInIcon, Cancel as NotCheckedInIcon } from '@material-ui/icons/';
import { useCreateTicketChecking } from '../hooks/useCreateTicketChecking';
import { MethodWayEnum } from '../../../../elb-card-reading/components/NewElbCardReading/method-enum';
import { useSnackbar } from '../../../../lib/hooks';

function EventCheckingTickets() {
  const searchParams = new URLSearchParams(window.location.search);
  const eventId = searchParams.get('eventId');
  const { data, isError, isLoading, isSuccess } = useGetActiveV2TicketsByEvent(eventId);
  const {
    mutate,
    isSuccess: mutateIsSuccess,
    isLoading: mutateIsLoading,
    isError: mutateIsError,
    error,
    reset,
  } = useCreateTicketChecking();

  const [open, setOpen] = useState(false);
  const [currentTicketNumber, setCurrentTicketNumber] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const handleClose = () => {
    setOpen(false);
    setCurrentTicketNumber('');
    reset();
  };

  console.log({ currentTicketNumber, mutateIsError, mutateIsLoading, mutateIsSuccess });
  useEffect(() => {
    if (mutateIsSuccess) {
      handleClose();
      enqueueSnackbar('Succesfully checked ticket in!', 'success', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
  }, [mutateIsSuccess]);

  return (
    <Box>
      <Typography variant="body1">Table is sorted by created date in descending order.</Typography>
      <TableContainer component={Paper} style={{ overflowX: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Ticket Number</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="center">Customer</TableCell>
              <TableCell align="center">Checked In</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isError && (
              <TableRow>
                <TableCell colSpan={5}>
                  <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    <Typography>Something went wrong!</Typography>
                  </Alert>
                </TableCell>
              </TableRow>
            )}
            {isLoading && (
              <TableRow
                style={{
                  position: 'relative',
                  height: '110px',
                }}
              >
                <TableCell colSpan={5}>
                  <LimitedBackdrop open={isLoading} style={{ padding: '8px' }}>
                    <CircularProgress color="primary" />
                  </LimitedBackdrop>
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              (isSuccess && data?.length > 0 ? (
                data.map(ticket => {
                  const { _id, customer, status, isCheckedIn, ticketNumber } = ticket;
                  const { email, fullName, profilePicture } = customer;
                  const { url } = profilePicture || {};

                  const humanReadableStatus =
                    status === 'FinalizedByOnlinePayment'
                      ? 'Finalized Online'
                      : 'FinalizedByOfficeEmployee'
                      ? 'Finalized in Office'
                      : 'UNKOWN_STATUS';

                  return (
                    <TableRow key={_id}>
                      <TableCell>{ticketNumber}</TableCell>
                      <TableCell>{humanReadableStatus}</TableCell>
                      <TableCell>
                        <Box style={{ display: 'flex', gap: '15px' }}>
                          {url && (
                            <img
                              src={url}
                              alt={`profile-pic-${fullName}`}
                              width="45px"
                              height="45px"
                            />
                          )}
                          <Box display={'flex'} justifyContent={'space-between'}>
                            <Box>
                              <Typography>{fullName}</Typography>
                              <Typography>{email}</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        {isCheckedIn ? (
                          <CheckedInIcon style={{ color: 'green' }} />
                        ) : (
                          <NotCheckedInIcon color="error" />
                        )}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={() => {
                            setOpen(true);
                            setCurrentTicketNumber(ticketNumber);
                          }}
                        >
                          Checking
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Alert severity="warning">
                      <AlertTitle>Not found</AlertTitle>
                      <Typography>No active ticket was found for the event.</Typography>
                    </Alert>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Box>
          <Dialog open={open}>
            <DialogTitle>Are you sure?</DialogTitle>
            {mutateIsError && (
              <DialogContent>
                <Typography style={{ color: 'red' }}>{error?.response?.data?.error}</Typography>
              </DialogContent>
            )}
            <DialogActions>
              <Button onClick={handleClose} variant="outlined">
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  mutate({
                    ticketNumber: currentTicketNumber,
                    eventId: eventId,
                    userToken: window.localStorage.getItem('token'),
                    methodWay: MethodWayEnum.EVENT_CHECKING_METHOD,
                  });
                }}
                disabled={mutateIsLoading || mutateIsError}
              >
                {mutateIsLoading && (
                  <>
                    <CircularProgress size={18} style={{ marginRight: '8px' }} />
                  </>
                )}
                Checking
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </TableContainer>
    </Box>
  );
}

export default EventCheckingTickets;
