import React, { useEffect, useRef, useState } from 'react';
import { useDebounce } from '../common/hooks';
import useGetElbCardBySearchKeyword from './useGetElbCardBySearchKeyword.hook';
import { Grid, TextField } from '@material-ui/core';
import CheckingSearchBarTable from './CheckingSearchBarTable';

function CheckingSearchBar({ isEventChecking = false }) {
  const [keyword, setKeyWord] = useState('');
  const [lastCardNumberValue, setLastCardNumberValue] = useState(null);
  const debouncedKeyword = useDebounce(keyword, 500);
  const { data, isSuccess, refetch, isError, isLoading } = useGetElbCardBySearchKeyword(
    debouncedKeyword,
  );
  console.log({ isSuccess, data });

  useEffect(() => {
    if (debouncedKeyword) {
      setLastCardNumberValue(debouncedKeyword);
      if (lastCardNumberValue !== debouncedKeyword) {
        refetch(keyword);
        if (keywordInputRef?.current) {
          keywordInputRef.current.focus();
          keywordInputRef.current.select();
        }
      }
    }
  }, [debouncedKeyword, refetch, keyword, lastCardNumberValue]);

  const keywordInputRef = useRef(null);
  const handleKeyDown = event => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  return (
    <div>
      <Grid
        style={{ display: 'flex', alignItems: 'center' }}
        container
        direction="row"
        justifyContent="center"
      >
        <form style={{  width: '20vw' }}>
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            required
            InputLabelProps={{
              shrink: true,
            }}
            name="keyword"
            label="Search Keyword"
            type="text"
            value={keyword}
            onChange={e => setKeyWord(e.target.value)}
            autoFocus
            inputRef={keywordInputRef}
            onKeyDown={event => handleKeyDown(event)}
          />
        </form>
      </Grid>
      <CheckingSearchBarTable
        data={data}
        isError={isError}
        isLoading={isLoading}
        isSuccess={isSuccess}
        keyword={keyword}
        isEventChecking={isEventChecking}
      />
    </div>
  );
}

export default CheckingSearchBar;
