import React from 'react';
import useGetBillingAccounts from './useGetBillingAccounts.hook';
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { FillerSpinner } from '../lib/components';
import { Alert, AlertTitle } from '@material-ui/lab';

function BillingAccountsSelect({
  billingAccount,
  handleChange,
  style,
  inputName = 'billingAccountId',
}) {
  const { data, isError, isLoading, isSuccess } = useGetBillingAccounts();
  return (
    <>
      {isLoading && (
        <Typography>
          <FillerSpinner />
          Loading...
        </Typography>
      )}
      {isError && (
        <Alert severity="error" style={{ padding: '4px', margin: '4px' }}>
          <AlertTitle>Error</AlertTitle>
          <Typography>An error occured during loading statuses</Typography>
        </Alert>
      )}
      {isSuccess && (
        <FormControl style={style}>
          <InputLabel>Billing account</InputLabel>
          <Select
            id={inputName}
            name={inputName}
            value={billingAccount}
            onChange={handleChange}
            variant="outlined"
          >
            <MenuItem value="" disabled></MenuItem>

            {data.length > 0 ? (
              data.map(billingAcc => (
                <MenuItem key={billingAcc._id} value={billingAcc._id}>
                  {billingAcc.name}
                </MenuItem>
              ))
            ) : (
              <></>
            )}
          </Select>
        </FormControl>
      )}
    </>
  );
}

export default BillingAccountsSelect;
