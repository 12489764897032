import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';
import { format, isFuture } from 'date-fns';
import { LimitedBackdrop } from '../common/components/LimitedBackdrop/LimitedBackdrop';
import { CheckCircle as ValidCardIcon, Cancel as InvalidCardIcon } from '@material-ui/icons/';
import CheckingSearchBarCreateWithResultDialog from './CheckingSearchBarCreateWithResultDialog';

function CheckingSearchBarTable({
  isError,
  isLoading,
  data,
  isSuccess,
  keyword,
  isEventChecking = false,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <TableContainer component={Paper} style={{ overflowX: 'auto' }}>
      {/* DESKTOP/TABLE VIEW */}
      <Box style={{ display: isMobile ? 'none' : 'block' }}>
        <Table>
          {keyword !== '' && (
            <TableHead>
              <TableRow>
                <TableCell>ELB Card Number</TableCell>
                <TableCell align="center">Type</TableCell>
                <TableCell>Online ELB Card Number</TableCell>
                <TableCell>Expiration Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="center">Customer</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {isError && (
              <TableRow>
                <TableCell colSpan={7}>
                  <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    <Typography>Something went wrong!</Typography>
                  </Alert>
                </TableCell>
              </TableRow>
            )}
            {isLoading && keyword !== '' && (
              <TableRow
                style={{
                  position: 'relative',
                  height: '110px',
                }}
              >
                <TableCell colSpan={7}>
                  <LimitedBackdrop open={isLoading} style={{ padding: '8px' }}>
                    <CircularProgress color="primary" />
                  </LimitedBackdrop>
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              (isSuccess && data?.length > 0 ? (
                data.map(elbCard => {
                  const {
                    _id,
                    customer,
                    type,
                    status,
                    cardNumber,
                    onlineCardNumber,
                    expirationDate,
                    elbCardProductSnapshot,
                  } = elbCard;
                  const { email, fullName, profilePicture } = customer?.[0];
                  const { name: typeName } = type?.[0];
                  const { name: productTypeName } = elbCardProductSnapshot?.[0] || {};

                  return (
                    <TableRow key={_id}>
                      <TableCell>{cardNumber}</TableCell>
                      <TableCell>
                        {typeName === 'UNUSED_Product_related_type' ? productTypeName : typeName}
                      </TableCell>
                      <TableCell>{onlineCardNumber}</TableCell>
                      <TableCell>
                        {expirationDate && format(new Date(expirationDate), 'yyyy-MM-dd HH:mm')}
                      </TableCell>
                      <TableCell>{status}</TableCell>
                      <TableCell>
                        <Box display={'flex'} justifyContent={'space-between'}>
                          <Box>
                            <Typography>{fullName}</Typography>
                            <Typography>{email}</Typography>
                          </Box>
                          {profilePicture?.url ? (
                            <img src={profilePicture.url} alt={fullName} width="45px" />
                          ) : (
                            ''
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <CheckingSearchBarCreateWithResultDialog
                          elbCard={elbCard}
                          isEventChecking={isEventChecking}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={7}>
                    <Alert severity="warning">
                      <AlertTitle>Not found</AlertTitle>
                      <Typography>No ELB card was found with provided keyword.</Typography>
                    </Alert>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>

      {/* MOBILE/CARD VIEW */}
      <Box style={{ display: isMobile ? 'block' : 'none' }}>
        {isError && (
          <Box>
            <TableCell colSpan={7}>
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                <Typography>Something went wrong!</Typography>
              </Alert>
            </TableCell>
          </Box>
        )}
        {isLoading && keyword !== '' &&  (
          <Box
            style={{
              position: 'relative',
              height: '110px',
            }}
          >
            <TableCell colSpan={7}>
              <LimitedBackdrop open={isLoading} style={{ padding: '8px' }}>
                <CircularProgress color="primary" />
              </LimitedBackdrop>
            </TableCell>
          </Box>
        )}
        {!isLoading &&
          (isSuccess && data?.length > 0 ? (
            data.map(elbCard => {
              const { _id, customer, status, onlineCardNumber, expirationDate } = elbCard;
              const { email, fullName, profilePicture } = customer?.[0];
              const isValidCard = status === 'Active' && isFuture(new Date(expirationDate));
              return (
                <Box
                  key={_id}
                  style={{
                    border: '1px solid #ccc',
                    marginBottom: '8px',
                    padding: '8px',
                    borderRadius: '4px',
                  }}
                >
                  <Typography>
                    <strong>Online ELB Card Number:</strong> {onlineCardNumber}
                  </Typography>
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>Valid?</Typography>
                    {isValidCard ? (
                      <ValidCardIcon style={{ color: 'green' }} />
                    ) : (
                      <InvalidCardIcon color="error" />
                    )}
                  </Box>
                  <Typography>
                    <strong>Customer Name:</strong> {fullName}
                  </Typography>
                  <Typography>
                    <strong>Customer Email:</strong> {email}
                  </Typography>
                  <Box>
                    <strong>Profile Picture:</strong> <br />
                    {profilePicture?.url ? (
                      <img
                        src={profilePicture?.url}
                        alt="Profile"
                        width="50px"
                        style={{ display: 'block' }}
                      />
                    ) : (
                      ''
                    )}
                  </Box>
                  <Box marginTop={'6px'}>
                    {isValidCard && (
                      <CheckingSearchBarCreateWithResultDialog
                        elbCard={elbCard}
                        isEventChecking={isEventChecking}
                      />
                    )}
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box>
              <Alert severity="warning">
                <AlertTitle>Not found</AlertTitle>
                <Typography>No ELB card was found with provided keyword.</Typography>
              </Alert>
            </Box>
          ))}
      </Box>
    </TableContainer>
  );
}

export default CheckingSearchBarTable;
