import { useQuery } from '@tanstack/react-query';
import axios from 'axios';


export const useGetV2TicketByTicketNumber = (v2TicketNumber) => {
    return useQuery({
        queryKey: ['ticket-v2-by-ticket-number'],
        queryFn: async () => {
            const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL
            const response = await axios.get(
                `${REACT_APP_SERVER_URL}v2tickets/ticketNumber/${v2TicketNumber}`,
            );
            const data = await response.data;
            return data;
        },
        refetchOnWindowFocus: false,
    });
};
